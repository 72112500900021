body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.24;
  // background-color: #141a1f;
}

.title {
  font-weight: 800 !important;
  text-align: center;

  @media screen and (max-width: 980px) {
    font-size: 62px !important;
  }
}

h2 {
  font-weight: bold !important;
}

p {
  font-size: 16px !important;
}

.content {
  margin: 15px 0;
  text-align: center !important;

  strong {
    font-weight: bold;
    margin: 5px 0 !important;
    display: block;
  }
}

.header {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 980px) {
    flex-direction: column;
  }

  a {
    color: #141a1f;

    &:first-of-type {
      font-size: 18px;

      @media screen and (max-width: 980px) {
        font-size: 18px;
      }
    }

    &:last-of-type {
      @media screen and (max-width: 980px) {
        margin: 15px 0 0;

        .MuiButton-startIcon {
          display: none;
        }
      }
    }
  }
}

.photo-wrapper {
  margin: 16px 0 0;
  max-width: 100%;

  img {
    max-width: 100%;
  }
}

.image-wrapper {
  margin: 15px 0;
  max-width: 100%;

  img {
    max-width: 100%;
  }
}
